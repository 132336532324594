import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useUrlGenerator } from '../contexts/RoutesContext';

import PageMeta from '../partials/PageMeta';

import styles from '../../styles/pages/error.module.scss';

const propTypes = {
    statusCode: PropTypes.number,
};

const defaultProps = {
    statusCode: 404,
};

const ErrorPage = ({ statusCode }) => {
    const urlGenerator = useUrlGenerator();

    return (
        <div className={styles.container}>
            <PageMeta title={(statusCode || 404).toString()} />
            <div className={styles.inner}>
                <h1 className={styles.title}>
                    <FormattedMessage
                        id="site.error"
                        defaultMessage="Erreur"
                        description="Page title"
                    />
                </h1>
                <p className={styles.description}>
                    <FormattedMessage
                        id="site.error_description"
                        defaultMessage="Une erreur s'est produite"
                        description="Page description"
                    />
                </p>
                <div className={styles.actions}>
                    <Link to={urlGenerator('home')}>
                        <FormattedMessage
                            id="site.home"
                            defaultMessage="Retourner à l'accueil"
                            description="Page description"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
