/* eslint-disable react/no-array-index-key */
// import React from 'react';
// import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet';

const propTypes = {
    // title: PropTypes.string,
};

const defaultProps = {
    // title: 'Où sont passés tous les biscuits? | Adviso',
};

const PageMetaPartial = () => null;

PageMetaPartial.propTypes = propTypes;
PageMetaPartial.defaultProps = defaultProps;

export default PageMetaPartial;
