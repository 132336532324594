import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useHistory } from 'react-router';

import MainLayout from './layouts/Main';

import HomePage from './pages/Home';
import ErrorPage from './pages/Error';

const propTypes = {
    routes: PropTypes.object.isRequired, // eslint-disable-line
    micromag: PropTypes.shape({}),
};

const defaultProps = {
    micromag: null,
};

const App = ({ routes, micromag }) => {
    const history = useHistory();

    // Reset request on history change
    useEffect(() => {
        const unlisten = history.listen(() => {});
        return () => {
            unlisten();
        };
    }, [history]);

    return (
        <Switch>
            <Route exact path={routes.home}>
                <MainLayout>
                    <HomePage micromag={micromag} />
                </MainLayout>
            </Route>
            <Route path="*">
                <MainLayout>
                    <ErrorPage />
                </MainLayout>
            </Route>
        </Switch>
    );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
