export default {
    locale: 'fr',
    messages: {
        fr: {
            error: 'Erreur',
            errorDescription: "Une erreur s'est produite",
            home: "Retourner à l'accueil",
        },
        en: {
            error: 'Error',
            errorDescription: 'An error occured',
            home: 'Go home',
        },
    },
    routes: {
        home: '/',
    },
    pages: {
        home: {
            title: 'Carte de noel',
            body: '<p>Carte de noel</p>',
        },
    },
};
