import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Viewer from '@micromag/viewer';
import { useLocation } from 'react-router';

import classNames from 'classnames';
import PageMeta from '../partials/PageMeta';
import Button from '../buttons/Normal';

import { useWindowSize } from '../contexts/SiteContext';

import styles from '../../styles/pages/home.module.scss';

const propTypes = {
    micromag: PropTypes.shape({}),
};

const defaultProps = {
    micromag: null,
};

const HomePage = ({ micromag }) => {
    const { width, height } = useWindowSize();
    const [playing, setPlaying] = useState(true);
    const [color, setColor] = useState('green');
    const location = useLocation();
    const [fullscreen, setFullscreen] = useState(false);

    const onStart = useCallback(() => {
        setPlaying(true);
    }, [setPlaying]);

    const onClose = useCallback(() => {
        // console.log('close');
    }, []);

    const onScreenChange = useCallback(
        (screen) => {
            const { background = null } = screen || {};
            const { color: bgColor = null } = background || {};
            const { color: backgroundColor = null } = bgColor || {};
            const lowerColor = backgroundColor !== null ? backgroundColor.toLowerCase() : null;

            switch (lowerColor) {
                case '#ffffff':
                    setColor('white');
                    break;
                case '#c6e813':
                case '#ceeb10':
                    setColor('green');
                    break;
                default:
                    setColor('white');
                    break;
            }
        },
        [setColor],
    );

    const onEnd = useCallback(() => {
        setFullscreen(false);
        // console.log('end');
    }, [setFullscreen]);

    const onViewModeChange = useCallback(() => {
        // console.log('mode', mode);
    }, []);

    const theme = useMemo(() => {
        return {
            colors: {
                primary: 'rgb(25, 62, 106)',
                secondary: 'rgba(25, 62, 106)',
            },
            background: {
                color: color === 'white' ? '#FFF' : '#ceeb10',
            },
            textStyles: {
                title: {
                    color: {
                        color: 'rgb(25, 62, 106)',
                        alpha: 1,
                    },
                },
            },
            menuTheme: {
                colors: {
                    primary: 'rgb(25, 62, 106)',
                    secondary: 'rgba(25, 62, 106, 0.4)',
                },
            },
        };
    }, [color]);

    return (
        <div className={styles.container}>
            <PageMeta />
            {!playing ? (
                <Button style={styles.button} type="button" onClick={onStart}>
                    Start
                </Button>
            ) : null}
            <div className={styles.inner}>
                <Viewer
                    className={classNames([
                        styles.viewer,
                        { [styles.white]: color === 'white', [styles.green]: color === 'green' },
                    ])}
                    story={micromag}
                    theme={theme}
                    locale="fr"
                    memoryRouter
                    basePath={location.pathname}
                    closeable={fullscreen}
                    googleApiKey={null}
                    onClose={onClose}
                    onEnd={onEnd}
                    onScreenChange={onScreenChange}
                    onViewModeChange={onViewModeChange}
                    width={width}
                    height={height}
                    landscapeScreenMargin={0}
                    screenSizeOptions={{ desktopHeightRatio: 1 }}
                />
            </div>
        </div>
    );
};

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
