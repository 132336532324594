/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { RoutesProvider } from './contexts/RoutesContext';
import { PagesProvider } from './contexts/PagesContext';
import { SiteProvider } from './contexts/SiteContext';

import App from './App';

const propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    routes: PropTypes.objectOf(PropTypes.string),
    pages: PropTypes.objectOf(PropTypes.object),
    micromag: PropTypes.shape({}),
};

const defaultProps = {
    locale: 'fr',
    messages: {},
    routes: {},
    pages: {},
    micromag: null,
};

const Root = ({ locale, messages, routes, pages, micromag }) => {
    // console.log(messages);
    return (
        <IntlProvider locale={locale} messages={messages[locale] || messages}>
            <BrowserRouter>
                <RoutesProvider routes={routes}>
                    <PagesProvider pages={{ ...pages }}>
                        <SiteProvider>
                            <App routes={routes} micromag={micromag} />
                        </SiteProvider>
                    </PagesProvider>
                </RoutesProvider>
            </BrowserRouter>
        </IntlProvider>
    );
};

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;
