/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';

import styles from '../../styles/buttons/normal.module.scss';

const propTypes = {
    className: PropTypes.string,
    small: PropTypes.bool,
};

const defaultProps = {
    className: null,
    small: false,
};

const Normal = ({ className, small, ...props }) => (
    <Button
        className={classNames([
            styles.container,
            { [styles.small]: small, [className]: className !== null },
        ])}
        {...props}
    />
);

Normal.propTypes = propTypes;
Normal.defaultProps = defaultProps;

export default Normal;
