import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { useFonts } from '@folklore/fonts';

import styles from '../../styles/layouts/main.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    // isPrerender: PropTypes.bool,
    // fonts: PropTypes.shape({
    //     custom: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    // }),
};

const defaultProps = {
    // isPrerender: false,
    // fonts: null,
};

const MainLayout = ({ children }) => {
    // const { loaded: fontsLoaded } = useFonts(fonts);

    const mainStyle = {
        opacity: 1,
    };

    return (
        <div className={classNames([styles.container])} style={mainStyle}>
            {children}
        </div>
    );
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default MainLayout;
